import type { Tabby } from './TabbyResponse';
export type EditSection = 'customer' | 'shipping' | 'delivery' | 'payment' | 'order_summary';
export type Direction = 'forward' | 'backward';

export interface CustomerDetails {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

export interface Address {
  latitude: number;
  longitude: number;
  country: string;
  city: string;
  area: string;
  street: string;
  building: string;
  nearestLandmark?: string;
}

export enum DeliveryMethod {
  SAMEDAY = 1,
  EXPRESS = 2,
  INTL = 3,
}

export enum PaymentMethod {
  COD = 1,
  STRIPE,
  TAMARA,
  TABBY,
}

export interface CheckoutData {
  customerDetails: CustomerDetails;
  deliveryMethod: string;
  paymentMethod: PaymentMethod;
  cartUuid: string;
  address: Address;
}

export interface TabbyResponse extends Tabby {}
