import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIconARFlag, LazyIconENFlag, LazyIconEcoBottle, LazyIconFreeOfAdditives, LazyIconFructoseFree, LazyIconGlutenFree, LazyIconHypoallergenic, LazyIconLactoseFree, LazyIconOnlyActiveIngredients, LazyIconQualityTested, LazyIconVegan, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["IconARFlag", LazyIconARFlag],
["IconENFlag", LazyIconENFlag],
["IconEcoBottle", LazyIconEcoBottle],
["IconFreeOfAdditives", LazyIconFreeOfAdditives],
["IconFructoseFree", LazyIconFructoseFree],
["IconGlutenFree", LazyIconGlutenFree],
["IconHypoallergenic", LazyIconHypoallergenic],
["IconLactoseFree", LazyIconLactoseFree],
["IconOnlyActiveIngredients", LazyIconOnlyActiveIngredients],
["IconQualityTested", LazyIconQualityTested],
["IconVegan", LazyIconVegan],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
