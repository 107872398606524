<template>
  <div>
    <div
      class="flex items-center bg-white rounded-lg"
      :class="[
        !showQuantityButtons ? 'justify-center border border-black/20' : 'justify-between',
        isCart ? '' : 'lg:hidden',
      ]"
    >
      <button
        v-if="showQuantityButtons && selectedQuantity !== quantityOptions[0].value"
        @click="decreaseQuantity"
        class="p-1 px-1.5 bg-primary-100 text-primary-1000 rounded-lg leading-none"
      >
        <Icon name="tabler:minus" class="h-3 w-3" aria-hidden="true" />
      </button>
      <button
        v-else-if="showQuantityButtons && selectedQuantity === quantityOptions[0].value"
        class="p-1 px-1.5 bg-primary-100 text-primary-1000 rounded-lg leading-none"
        @click="emit('removeFromCart')"
      >
        <Icon name="tabler:trash-x" class="h-4 w-4" aria-hidden="true" />
      </button>
      <span
        @click="
          () => {
            if (!isCart) {
              showModal = true;
            }
          }
        "
        class="self-center px-4 text-center"
        :class="isProductDetail ? 'py-3.5' : 'py-2'"
        >{{ selectedQuantity }}</span
      >
      <button
        v-if="showQuantityButtons"
        @click="increaseQuantity"
        class="p-1 px-1.5 bg-primary-100 text-primary-1000 rounded-lg leading-none disabled:cursor-not-allowed"
        :disabled="selectedQuantity === quantityOptions[quantityOptions.length - 1].value"
      >
        <Icon name="tabler:plus" class="h-3 w-3" aria-hidden="true" />
      </button>
    </div>

    <!-- Modal -->
    <transition name="fade">
      <Teleport to="body">
        <div v-if="showModal" class="fixed inset-0 bg-black bg-opacity-50 z-[9999] flex items-center justify-center">
          <div ref="modalRef" class="bg-white p-4 rounded-lg max-h-full overflow-auto w-full max-w-sm mx-4">
            <div class="flex items-center justify-end border-b pb-2 mb-4">
              <h2 class="text-center font-bold ltr:mr-auto rtl:ml-auto">{{ t('common.quantity') }}</h2>
              <button @click="showModal = false">
                <Icon name="tabler:x" class="h-5 w-5 text-gray-600" aria-hidden="true" />
              </button>
            </div>
            <div class="grid grid-cols-1 max-h-[300px] overflow-y-auto gap-4 px-4">
              <button
                v-for="qty in quantityOptions"
                :key="qty.value"
                @click.stop="selectQuantity(qty.value)"
                class="p-2 bg-primary-100 text-primary-1000 rounded"
                :class="selectedQuantity === qty.value ? 'bg-primary-1000 text-white' : 'bg-primary-100'"
              >
                {{ qty.value }}
              </button>
            </div>
          </div>
        </div>
      </Teleport>
    </transition>

    <HeadlessListbox v-model="selectedQuantity" class="hidden w-full" :class="isCart ? 'hidden' : 'lg:block'">
      <div class="relative mt-1">
        <HeadlessListboxLabel class="text-xs font-bold uppercase text-primary-1000" v-if="showLabel">{{
          t('common.quantity')
        }}</HeadlessListboxLabel>
        <HeadlessListboxButton
          class="relative w-full cursor-default rounded-lg bg-white border py-1.5 pr-3 text-left focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-300 sm:text-sm"
        >
          <span class="selected-quantity block truncate" :class="isProductDetail ? 'py-2' : 'py-0.5'">{{
            selectedQuantity
          }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <Icon name="tabler:chevron-down" class="h-5 w-5 text-primary-400" aria-hidden="true" />
          </span>
        </HeadlessListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <HeadlessListboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-30"
            :class="dropdownDirection == 'bottom' ? 'top-full' : 'bottom-full'"
          >
            <HeadlessListboxOption
              v-slot="{ active, selected }"
              v-for="qty in quantityOptions"
              :key="qty.value"
              :value="qty.value"
              as="template"
            >
              <li
                :class="[
                  active ? 'bg-primary-100 text-primary-900' : 'text-gray-900',
                  'relative cursor-default select-none py-1',
                ]"
              >
                <span
                  class="text-left pl-3"
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block',
                    'rtl:pr-3 rtl:text-center',
                    isProductDetail ? 'text-center pl-0' : '',
                  ]"
                  >{{ qty.value }}</span
                >
                <span v-if="selected" class="absolute inset-y-0 flex items-center text-primary-600 right-0 pr-3">
                  <Icon name="tabler:check" class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </HeadlessListboxOption>
          </HeadlessListboxOptions>
        </transition>
      </div>
    </HeadlessListbox>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
const props = defineProps({
  showLabel: {
    type: Boolean,
    default: true,
  },
  showQuantityButtons: {
    type: Boolean,
    default: false,
  },
  isProductDetail: {
    type: Boolean,
    default: false,
  },
  isCart: {
    type: Boolean,
    default: false,
  },
  dropdownDirection: {
    type: String as PropType<'top' | 'bottom'>,
    default: 'bottom',
  },
  modelValue: {
    type: Number,
    default: 1,
  },
});

const { width } = useWindowSize();
const { t } = useI18n();
const isMobile = computed(() => width.value <= 768);

const emit = defineEmits(['update:modelValue', 'removeFromCart']);

const quantityOptions = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
];
const selectedQuantity = ref(props.modelValue);

const showModal = ref(false);
const modalRef = ref(null);

onClickOutside(modalRef, () => {
  showModal.value = false;
});

const increaseQuantity = () => {
  if (selectedQuantity.value < quantityOptions[quantityOptions.length - 1].value) {
    selectedQuantity.value++;
    emit('update:modelValue', selectedQuantity.value);
  }
};

const decreaseQuantity = () => {
  if (selectedQuantity.value > quantityOptions[0].value) {
    selectedQuantity.value--;
    emit('update:modelValue', selectedQuantity.value);
  }
};

const selectQuantity = (value) => {
  selectedQuantity.value = value;
  emit('update:modelValue', value);
  showModal.value = false;
};

watch(selectedQuantity, (newValue) => {
  emit('update:modelValue', newValue);
});

watchEffect(() => {
  if (selectedQuantity.value !== props.modelValue) {
    selectedQuantity.value = props.modelValue;
  }
});
</script>

<style scoped>
.quantity-selector {
  margin: 0 10px;
  @apply text-primary-1000 font-bold text-xl outline-none;
}

@media (max-width: 768px) {
  .listbox-options-dropdown {
    @apply bottom-full;
  }
}
</style>
